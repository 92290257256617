// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-adult-sunday-school-js": () => import("./../src/pages/AdultSundaySchool.js" /* webpackChunkName: "component---src-pages-adult-sunday-school-js" */),
  "component---src-pages-children-activities-js": () => import("./../src/pages/ChildrenActivities.js" /* webpackChunkName: "component---src-pages-children-activities-js" */),
  "component---src-pages-children-group-js": () => import("./../src/pages/ChildrenGroup.js" /* webpackChunkName: "component---src-pages-children-group-js" */),
  "component---src-pages-contact-list-js": () => import("./../src/pages/ContactList.js" /* webpackChunkName: "component---src-pages-contact-list-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-etc-js": () => import("./../src/pages/etc.js" /* webpackChunkName: "component---src-pages-etc-js" */),
  "component---src-pages-feedback-js": () => import("./../src/pages/Feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-fellowship-group-js": () => import("./../src/pages/FellowshipGroup.js" /* webpackChunkName: "component---src-pages-fellowship-group-js" */),
  "component---src-pages-giving-info-js": () => import("./../src/pages/GivingInfo.js" /* webpackChunkName: "component---src-pages-giving-info-js" */),
  "component---src-pages-giving-report-js": () => import("./../src/pages/GivingReport.js" /* webpackChunkName: "component---src-pages-giving-report-js" */),
  "component---src-pages-hymns-js": () => import("./../src/pages/Hymns.js" /* webpackChunkName: "component---src-pages-hymns-js" */),
  "component---src-pages-iam-new-js": () => import("./../src/pages/IamNew.js" /* webpackChunkName: "component---src-pages-iam-new-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../src/pages/Location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-message-js": () => import("./../src/pages/Message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-ministry-js": () => import("./../src/pages/Ministry.js" /* webpackChunkName: "component---src-pages-ministry-js" */),
  "component---src-pages-ministry-training-js": () => import("./../src/pages/MinistryTraining.js" /* webpackChunkName: "component---src-pages-ministry-training-js" */),
  "component---src-pages-mission-js": () => import("./../src/pages/Mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-past-gathering-js": () => import("./../src/pages/PastGathering.js" /* webpackChunkName: "component---src-pages-past-gathering-js" */),
  "component---src-pages-prayer-request-js": () => import("./../src/pages/PrayerRequest.js" /* webpackChunkName: "component---src-pages-prayer-request-js" */),
  "component---src-pages-principals-js": () => import("./../src/pages/Principals.js" /* webpackChunkName: "component---src-pages-principals-js" */),
  "component---src-pages-sermons-archive-js": () => import("./../src/pages/SermonsArchive.js" /* webpackChunkName: "component---src-pages-sermons-archive-js" */),
  "component---src-pages-sign-up-js": () => import("./../src/pages/SignUp.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-special-gathering-js": () => import("./../src/pages/SpecialGathering.js" /* webpackChunkName: "component---src-pages-special-gathering-js" */),
  "component---src-pages-staff-js": () => import("./../src/pages/Staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-student-group-js": () => import("./../src/pages/StudentGroup.js" /* webpackChunkName: "component---src-pages-student-group-js" */),
  "component---src-pages-sunday-worship-info-js": () => import("./../src/pages/SundayWorshipInfo.js" /* webpackChunkName: "component---src-pages-sunday-worship-info-js" */),
  "component---src-pages-sunday-worship-js": () => import("./../src/pages/SundayWorship.js" /* webpackChunkName: "component---src-pages-sunday-worship-js" */),
  "component---src-pages-watch-live-js": () => import("./../src/pages/WatchLive.js" /* webpackChunkName: "component---src-pages-watch-live-js" */),
  "component---src-pages-west-plant-church-js": () => import("./../src/pages/WestPlantChurch.js" /* webpackChunkName: "component---src-pages-west-plant-church-js" */),
  "component---src-pages-youth-activities-js": () => import("./../src/pages/YouthActivities.js" /* webpackChunkName: "component---src-pages-youth-activities-js" */)
}

